import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";

const Home = () => {
  return (
    <>
      <br />
      <BannerMessage closable={false} type={BannerMessageType.Info} title={"Temporary System Outage"} description={"We are working hard to bring you this service. We have no ETA on when this service will become live. Join our discord for updates."} />
    </>
  );
}

export default Home;