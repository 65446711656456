import "./NotFound.css"

const NotFound = () => {
  return (
    <>
      <h1>404 - Page not found</h1>
    </>
  );
}

export default NotFound;