import { useState } from "react"
import "./BannerMessage.css"

export enum BannerMessageType {
	Error,
	Warning,
	Info
}

export type BannerMesageContents = {
	title: string,
	description: string,
	type: BannerMessageType,
	closable: boolean
}

const BannerMessage = (props: BannerMesageContents) => {

	const [closed, setClosed] = useState<boolean>(false);

	const getBackgroundColor = (type: BannerMessageType): string => {
		switch (type) {
			case BannerMessageType.Info: return "#d9edf7";
			case BannerMessageType.Warning: return "#feca57";
			case BannerMessageType.Error: return "#ff6b6b";
			default: return "";
		}
	}

	if (closed) {
		return <></>;
	}

	return (
		<div style={{ backgroundColor: getBackgroundColor(props.type) }} className="banner-message-container">
			<div className="banner-header">
				<p className="banner-message-title">{props.title}</p>
				{
					props.closable ?
						<button className="banner-close" onClick={() => setClosed(true)}>x</button>
						:
						<br />
				}
			</div>
			<p className="banner-message-description">{props.description}</p>
		</div>
	);
}

export default BannerMessage;