import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Layout';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import "./Layout.css"
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy';
import Rules from './Pages/Legal/Rules';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="legal/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="rules" element={<Rules />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);