import { Outlet, Link } from "react-router-dom";

import logo from "./static/residual_logo.png"

const Layout = () => {
  return (
    <>
      <header>
        <div className="header-img-container">
          <img className="header-img" src={logo} alt="Residual RP logo" />
        </div>
        <nav className="nav-container">
          <ul className="nav-list">
            <li className="nav-list-item">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/rules">Rules</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/legal/privacypolicy">Privacy Policy</Link>
            </li>
            <li className="nav-list-item">
              <Link to="https://discord.gg/residualrp">Discord</Link>
            </li>
          </ul>
        </nav>
      </header>

      <Outlet />
    </>
  )
};

export default Layout;